import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import ShopNav from "../components/global/ShopNav";
import ProgrammesNav from "../components/global/ProgrammesNav";
import Login from "../components/global/Login";
import { mixins } from "../styles/mixins";
import { GlobalStyle } from "../styles/global-styles";
import Asterisk from "../images/asterisk.inline.svg";
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import SnipcartItemAdded from "../components/global/SnipcartItemAdded";
import RegisterInterestScrollButton from "../components/global/RegisterInterestScrollButton";
import TypeformEmbed from "../components/page-components/ArtistDevelopmentVoltageCircus/TypeformEmbed";
import ConsentBanner from "../components/global/ConsentBanner";

export default function Layout(data) {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isLoginActive, setIsLoginActive] = useState(false);
  const [isSecondaryMenuActive, setIsSecondaryMenuActive] = useState(false);
  const [isMerchMenuActive, setIsMerchMenuActive] = useState(false);
  const [isSamplePackMenuActive, setIsSamplePackMenuActive] = useState(false);
  const [cartQty, setCartQty] = useState();

  const { state } = useContext(SnipcartContext);

  const setVH = () => {
    document.querySelector(":root").style.setProperty("--vh", window.innerHeight / 100 + "px");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setVH();
      window.addEventListener("resize", setVH);
    }

    return () => {
      window.removeEventListener("resize", setVH);
    };
  });

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     if (cookiePolicy === true) {
  //       const targets = gsap.utils.toArray([".cookie-banner", "header"]);
  //       gsap.to(targets, { y: 0 });
  //     } else {
  //       const bannerH = document.querySelector(".cookie-banner").offsetHeight;
  //       const targets = gsap.utils.toArray([".cookie-banner", "header"]);
  //       gsap.to(targets, { y: bannerH });
  //     }
  //   }
  // }, [cookiePolicy]);

  // const handleSetCookie = () => {
  //   if (typeof window !== "undefined") {
  //     setCookiePolicy(true);
  //     window.localStorage.setItem("cookieAccepted", true);
  //     setTimeout(function () {
  //       setCookiePolicyHidden(true);
  //     }, 1000);
  //   }
  // };

  // useEffect(() => {
  //   if (Snipcart !== undefined || Snipcart !== "undefined") {
  //     Snipcart.events.on("cart.confirmed", (cartConfirmResponse) => {
  //       console.log(cartConfirmResponse);
  //       const cartItems = cartConfirmResponse.items.items;

  //       cartItems.map((cartItem) => {
  //         if (cartItem.categories.includes("onDemand")) {
  //           console.log("order contains onDemand product");
  //           gtag("event", "conversion", {
  //             send_to: "AW-11012634547/offGCLTNpIsYELPvnYMp",
  //             value: 120.0,
  //             currency: "GBP",
  //             transaction_id: `onDemand_${cartConfirmResponse.invoiceNumber}`,
  //           });
  //         }
  //         if (cartItem.categories.includes("plugin")) {
  //           console.log("order contains Plugin product");
  //           gtag("event", "conversion", {
  //             send_to: "AW-11012634547/sQsECLfNpIsYELPvnYMp",
  //             value: 49.99,
  //             currency: "GBP",
  //             transaction_id: `plugin_${cartConfirmResponse.invoiceNumber}`,
  //           });
  //         }
  //         if (cartItem.categories.includes("merch")) {
  //           console.log("order contains Merch product");
  //           gtag("event", "conversion", {
  //             send_to: "AW-11012634547/YobHCL3NpIsYELPvnYMp",
  //             value: 24.99,
  //             currency: "GBP",
  //             transaction_id: `merch_${cartConfirmResponse.invoiceNumber}`,
  //           });
  //         }
  //         if (cartItem.categories.includes("samplePack")) {
  //           console.log("order contains Sample Pack product");
  //           gtag("event", "conversion", {
  //             send_to: "AW-11012634547/1ccqCLrNpIsYELPvnYMp",
  //             value: 19.99,
  //             currency: "GBP",
  //             transaction_id: `samplePack_${cartConfirmResponse.invoiceNumber}`,
  //           });
  //         }
  //         if (cartItem.categories.includes("ableton-template")) {
  //           console.log("order contains Template product");
  //           gtag("event", "conversion", {
  //             send_to: "AW-11012634547/BPISCLjOpIsYELPvnYMp",
  //             value: 29.99,
  //             currency: "GBP",
  //             transaction_id: `abletotemplate_${cartConfirmResponse.invoiceNumber}`,
  //           });
  //         }
  //       });
  //     });
  //   }
  // }, [Snipcart]);

  const checkItemsForVariableProducts = () => {
    const variableCartItems = Array.from(
      document.querySelectorAll("#snipcart .snipcart-item-line .snipcart-dropdown-custom-field")
    );

    if (variableCartItems.length > 0) {
      variableCartItems.forEach((item) => {
        // item.value === undefined;
        const checkouTButton = document.querySelector("#snipcart .snipcart-cart__checkout-button");
        if (item.value === "") {
          item.style.backgroundColor = "red";
          checkouTButton.style.display = "none";
        } else {
          item.style.backgroundColor = "transparent";
          checkouTButton.style.display = "block";
        }
      });
    }
  };

  useEffect(() => {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("snipcart");

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      checkItemsForVariableProducts();

      observer.disconnect();
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  });

  return (
    <ThemeProvider theme={mixins}>
      {
        // ================================
        // Inject Global Styles
        // ================================
      }
      <GlobalStyle />

      {
        // ================================
        // Hide Header component for pages specifed in the noHeader array
        // ================================
        <Header
          className={`offscreen-translator ${
            data.path.indexOf("/course-player/") > -1 ? "course-player-header" : ""
          }`}
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          isLoginActive={isLoginActive}
          setIsLoginActive={setIsLoginActive}
          snipcart={state}
        />
      }
      {<SnipcartItemAdded />}
      {
        // ================================
        // Render Children
        // ================================
        data.path.indexOf("/course-player/") > -1 ? (
          <div className="zindex">{data.children}</div>
        ) : (
          <div className="offscreen-translator">{data.children}</div>
        )
      }
      {
        // ================================
        // Hide Footer component for pages specifed in the noFooter array
        // ================================
        data.path.indexOf("/course-player/") > -1 ? (
          ""
        ) : (
          <Footer
            className="offscreen-translator"
            isMenuActive={isMenuActive}
            setIsMenuActive={setIsMenuActive}
            isLoginActive={isLoginActive}
            setIsLoginActive={setIsLoginActive}
          />
        )
      }
      <div className="contact_button_fixed">
        {
          // ================================
          // Hide Contact Button for pages specifed in the noFooter array
          // ================================

          data.path.indexOf("programmes/interactive/production-certificate") > -1 ||
          data.path.indexOf("programmes/interactive/creativity-unlocked") > -1 ||
          data.path.indexOf("programmes/interactive/club-ready") > -1 ||
          data.path.indexOf("programmes/interactive/production-masterclass") > -1 ? (
            <RegisterInterestScrollButton
              hash={"#register-interest"}
              text={"Enquire Now!"}
              isFixedInFooter={true}
              borderColor={"black"}
            />
          ) : (
            ""
          )
        }
        {
          // ================================
          // Hide Contact Button for pages specifed in the noFooter array
          // ================================
          data.path.indexOf("/course-player/") > -1 ? (
            ""
          ) : (
            <a
              href="mailto:info@toolroomacademy.com"
              className="capsule_button white offscreen-translator">
              <Asterisk className="asterisk" />
              <span>Contact</span>
            </a>
          )
        }
      </div>
      <ShopNav
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
        isMerchMenuActive={isMerchMenuActive}
        setIsMerchMenuActive={setIsMerchMenuActive}
        isSamplePackMenuActive={isSamplePackMenuActive}
        setIsSamplePackMenuActive={setIsSamplePackMenuActive}
        isSecondaryMenuActive={isSecondaryMenuActive}
        setIsSecondaryMenuActive={setIsSecondaryMenuActive}
      />
      <ProgrammesNav
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
        isMerchMenuActive={isMerchMenuActive}
        setIsMerchMenuActive={setIsMerchMenuActive}
        isSamplePackMenuActive={isSamplePackMenuActive}
        setIsSamplePackMenuActive={setIsSamplePackMenuActive}
        isSecondaryMenuActive={isSecondaryMenuActive}
        setIsSecondaryMenuActive={setIsSecondaryMenuActive}
      />
      <Login
        isLoginActive={isLoginActive}
        setIsLoginActive={setIsLoginActive}
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
      />
      <TypeformEmbed />
      <ConsentBanner />
    </ThemeProvider>
  );
}
