const mixins = {
    opacity: (opacity) => `
        opacity: ${opacity};
    `,
    fontSize: (size) => `
        font-size: ${size}px; 
        font-size: ${(size / 10) * 1}rem;`
    ,
    fontSizeLineHeight: (size, lineHeight) => `
        line-height:${(size / 10) * lineHeight}rem;`
    ,
    easing: (time)=> `
        transition:${time}s all ease-in-out;`
    ,
    tomato: (weight)=> `
        font-family: tomato--${weight};
    `,
    telegraf: (weight)=> `
        font-family: telegraf--${weight};
    `,
    button: (color) => `
        cursor:pointer; 
        font-family: var(--font_body); 
        font-style:italic; color:var(${color ? color : '--color_primary'}); 
        padding:1rem; 
        background:transparent; 
        border:1px solid var(${color ? color : '--color_primary'}); 
        text-decoration:none;`
    ,
    buttonHover: (color, bg) => `
        background:var(${bg ? bg : '--color_primary'}); 
        color:var(${color ? color : '--color_secondary'});`
    ,
    buttonSolid: (color, bg) => `
        cursor:pointer; 
        background:var(${bg ? bg : '--color_primary'}); 
        border:1px solid var(${bg ? bg : '--color_primary'}); 
        color:var(${color ? color : '--color_secondary'});`
    ,
}

export {
    mixins
}