import React from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GrClose} from 'react-icons/gr'
import {BsArrowRightShort} from 'react-icons/bs'
import { useStaticQuery, graphql } from "gatsby"
import gsap from 'gsap'

export default function MobileNav({
    setIsMenuActive, 
    isMenuActive,
    isLoggedIn,
    logout,
    handleNav,
    showLoginModal,
}) {
    const data = useStaticQuery(graphql`
    {
        menu: wpMenu(name: {eq: "Main Menu"}) {
            menuItems {
                nodes {
                    url
                    label
                }
            }
        }
    }
  `)

  

  const handleMobileLoginNav = () => {
    const nav = document.querySelector('#login')
    const mobNav = gsap.utils.toArray('#mobileNav')
    gsap.to(nav, {opacity:1, duration:0, pointerEvents:'all'})
    gsap.to(mobNav, {x:'100vw', opacity:0})
    gsap.to(nav, {x:0, delay:0.25})
  }
  const handleMobileShopNav = () => {
    const nav = document.querySelector('#shopNav')
    const mobNav = gsap.utils.toArray('#mobileNav')
    gsap.to(nav, {opacity:1, duration:0, pointerEvents:'all'})
    gsap.to(mobNav, {x:'100vw', opacity:0})
    gsap.to(nav, {x:0, delay:0.25})
  }
  const handleMobileProgrammesNav = () => {
    const nav = gsap.utils.toArray('#programmesNav')
    const mobNav = gsap.utils.toArray('#mobileNav')    
    gsap.to(nav, {opacity:1, duration:0, pointerEvents:'all'})
    gsap.to(mobNav, {x:'100vw', opacity:0})
    gsap.to(nav, {x:0, delay:0.25})
  }

    return (
        <Styling id="mobileNav"  className="navs">
            <div className="main-inner">
                <div className="shopNav--close">
                    <button onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#mobileNav') } aria-label="Close Navigation">
                        <GrClose />
                    </button>
                </div>
                
                <nav id="mobile-nav" className="nav">
                    <ul>
                        {
                            data.menu.menuItems.nodes.map(
                                item => {
                                    let link
                                    if(item.url === '#shop'){
                                        link = <li key={'shopMobLinkA_1'}><button className="fake_link" onClick={(e) => handleMobileShopNav() }>{item.label} <BsArrowRightShort /></button></li>
                                    }
                                    else if(item.url === '#programmes'){
                                        link = <li key={'shopMobLinkA_2'}><button className="fake_link" onClick={(e) =>  handleMobileProgrammesNav() }>{item.label} <BsArrowRightShort /></button></li>
                                    }
                                    else{
                                        link = <li key={item.label+'_3'}><Link onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive) } to={item.url}>{item.label} <BsArrowRightShort /></Link></li>
                                    }
                                    return link
                                }
                            )
                        }
                    </ul>
                </nav>
            
                <ul className="cart_login">
                    {isLoggedIn() ? <li key={'shopMobLinkB_1'}><Link to="/course-dashboard/" onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#mobileNav') } className="login capsule_button white">My Courses</Link></li> : ''}
                    <li key={'shopMobLinkB_2'}>
                        <button className="login capsule_button white" onClick={
                            (e)=>{
                                if(isLoggedIn()){
                                    logout()
                                    handleNav(e, setIsMenuActive, isMenuActive, '#mobileNav') 
                                }else{
                                    handleMobileLoginNav()
                                }
                            }
                            }>
                            <span className="text">{
                                isLoggedIn() ? 'Logout' : 'Login'
                            }</span>
                        </button>
                    </li>
                </ul>
            </div>
        </Styling>
    )
}

