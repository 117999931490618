import React, { useState, useRef, useEffect } from "react";
import { Styling } from "./styled.js";
import gsap from "gsap";

export default function TypeformEmbed() {
  const handleHideSignup = () => {
    const iframe = document.querySelector("#typeform");

    gsap.to(iframe, {
      duration: 0.5,
      autoAlpha: 0,
      pointerEvents: "none",
    });
  };

  return (
    <Styling id="typeform">
      <div className="close" onClick={() => handleHideSignup()}>
        X
      </div>
      <div className="iframe-wrapper">
        <iframe
          id="typeform-full"
          width="100%"
          height="100%"
          frameBorder="0"
          src="https://8msvm8hayy6.typeform.com/to/XqsMKWWd"></iframe>
      </div>
    </Styling>
  );
}
