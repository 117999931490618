exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-course-dashboard-js": () => import("./../../../src/pages/course-dashboard.js" /* webpackChunkName: "component---src-pages-course-dashboard-js" */),
  "component---src-pages-demos-js": () => import("./../../../src/pages/demos.js" /* webpackChunkName: "component---src-pages-demos-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mastering-js": () => import("./../../../src/pages/mastering.js" /* webpackChunkName: "component---src-pages-mastering-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-programmes-artist-development-js": () => import("./../../../src/pages/programmes/artist-development.js" /* webpackChunkName: "component---src-pages-programmes-artist-development-js" */),
  "component---src-pages-programmes-degree-index-js": () => import("./../../../src/pages/programmes/degree/index.js" /* webpackChunkName: "component---src-pages-programmes-degree-index-js" */),
  "component---src-pages-programmes-index-js": () => import("./../../../src/pages/programmes/index.js" /* webpackChunkName: "component---src-pages-programmes-index-js" */),
  "component---src-pages-programmes-interactive-js": () => import("./../../../src/pages/programmes/interactive.js" /* webpackChunkName: "component---src-pages-programmes-interactive-js" */),
  "component---src-pages-programmes-on-demand-js": () => import("./../../../src/pages/programmes/on-demand.js" /* webpackChunkName: "component---src-pages-programmes-on-demand-js" */),
  "component---src-pages-redeem-js": () => import("./../../../src/pages/redeem.js" /* webpackChunkName: "component---src-pages-redeem-js" */),
  "component---src-pages-request-password-reset-js": () => import("./../../../src/pages/request-password-reset.js" /* webpackChunkName: "component---src-pages-request-password-reset-js" */),
  "component---src-pages-set-password-js": () => import("./../../../src/pages/set-password.js" /* webpackChunkName: "component---src-pages-set-password-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-merch-js": () => import("./../../../src/pages/shop/merch.js" /* webpackChunkName: "component---src-pages-shop-merch-js" */),
  "component---src-pages-shop-sample-packs-js": () => import("./../../../src/pages/shop/sample-packs.js" /* webpackChunkName: "component---src-pages-shop-sample-packs-js" */),
  "component---src-pages-student-success-js": () => import("./../../../src/pages/student-success.js" /* webpackChunkName: "component---src-pages-student-success-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-pages-thanks-register-interest-js": () => import("./../../../src/pages/thanks-register-interest.js" /* webpackChunkName: "component---src-pages-thanks-register-interest-js" */),
  "component---src-pages-we-are-listening-js": () => import("./../../../src/pages/we-are-listening.js" /* webpackChunkName: "component---src-pages-we-are-listening-js" */),
  "component---src-templates-course-player-js": () => import("./../../../src/templates/course-player.js" /* webpackChunkName: "component---src-templates-course-player-js" */),
  "component---src-templates-features-post-js": () => import("./../../../src/templates/features-post.js" /* webpackChunkName: "component---src-templates-features-post-js" */),
  "component---src-templates-interactive-programme-js": () => import("./../../../src/templates/interactive-programme.js" /* webpackChunkName: "component---src-templates-interactive-programme-js" */),
  "component---src-templates-landing-page-builder-js": () => import("./../../../src/templates/landing-page-builder.js" /* webpackChunkName: "component---src-templates-landing-page-builder-js" */),
  "component---src-templates-product-merch-js": () => import("./../../../src/templates/product-merch.js" /* webpackChunkName: "component---src-templates-product-merch-js" */),
  "component---src-templates-product-plugins-js": () => import("./../../../src/templates/product-plugins.js" /* webpackChunkName: "component---src-templates-product-plugins-js" */),
  "component---src-templates-product-sample-packs-js": () => import("./../../../src/templates/product-samplePacks.js" /* webpackChunkName: "component---src-templates-product-sample-packs-js" */),
  "component---src-templates-product-templates-js": () => import("./../../../src/templates/product-templates.js" /* webpackChunkName: "component---src-templates-product-templates-js" */),
  "component---src-templates-production-programme-js": () => import("./../../../src/templates/production-programme.js" /* webpackChunkName: "component---src-templates-production-programme-js" */),
  "component---src-templates-shop-category-js": () => import("./../../../src/templates/shop-category.js" /* webpackChunkName: "component---src-templates-shop-category-js" */),
  "component---src-templates-student-success-post-js": () => import("./../../../src/templates/student-success-post.js" /* webpackChunkName: "component---src-templates-student-success-post-js" */)
}

