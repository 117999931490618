import styled from 'styled-components'

const Styling = styled.div`
position: fixed;
top:0;
right:0;
height: 100%;
background:var(--white);
display: flex;
align-items: center;
flex-wrap: wrap;
align-content: center;
padding:4rem;
width: 100vw;
opacity: 0;
pointer-events: none;
transform:translateX(-100%);
overflow: hidden;

    @media only screen and (min-width: 1024px) {
    width: 30vw;
    transform:translateX(0%);
    }

    .closeLoginMobile,
    .closeLogin{
    position:absolute;
    top:4rem;
    right:4rem;
    padding:0;
    border:0;
    display: none;

        @media only screen and (min-width: 1024px) {
        display: block;
        }

        svg{
        ${({theme}) => theme.easing('0.5')}  
        }

        &:hover{
            svg{
            transform: rotate(90deg);
            }
        }

    }

    .closeLoginMobile{
    display: block;

        @media only screen and (min-width: 1024px) {
        display: none;
        }   
    }

    .title{
    width: 100%;
    font-size:clamp(4rem, 4vw, 8rem);
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    letter-spacing: -0.09em;
    display: flex;
    align-items:center;
    margin-bottom:4rem;

        svg{
        margin-right: 1rem;
        width: clamp(3rem, 2.5vw, 6rem);
            path{
                fill:var(--black);
            }
        }

    }

`

export { Styling }