import React, { useEffect, useState } from "react";
import { Styling } from "./styled.js";
import Arrow from "../../../images/arrow_down_right.inline.svg";
import gsap from "gsap";
import { GrClose } from "react-icons/gr";
import { handleLogin, isLoggedIn } from "../../../utils/auth";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";

export default function Login({ isLoginActive, setIsLoginActive, setIsMenuActive }) {
  let rememberedUser;
  if (typeof window !== "undefined") {
    rememberedUser = window?.localStorage?.rememberUser;
  } else {
    rememberedUser = undefined;
  }
  const [username, setUsername] = useState(rememberedUser);
  const [password, setPassword] = useState();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [resetEmail, setResetEmail] = useState();
  const [validationCode, setValidationCode] = useState();
  const [resetPassword, setResetPassword] = useState();
  const [remember, setRemember] = useState(false);
  const baseUrl = "https://admin.toolroomacademy.com/wp-json/bdpwr/v1/";
  const resetPasswordUrl = baseUrl + "reset-password";
  const validateCodeUrl = baseUrl + "validate-code";
  const setNewPasswordUrl = baseUrl + "set-password";

  function reset(resetEmail) {
    if (typeof window !== "undefined") {
      axios
        .post(resetPasswordUrl, {
          email: resetEmail,
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
          alert(
            "Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support."
          );
        });
    }
  }

  function validate(validationCode) {
    if (typeof window !== "undefined") {
      axios
        .post(validateCodeUrl, {
          email: resetEmail,
          code: String(validationCode),
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
          alert(
            "Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support."
          );
        });
    }
  }

  function setNewPassword(validationCode, resetPassword) {
    if (typeof window !== "undefined") {
      axios
        .post(setNewPasswordUrl, {
          email: resetEmail,
          code: String(validationCode),
          password: resetPassword,
        })
        .then((r) => {
          //console.log(r.data);
        })
        .catch((e) => {
          //console.log(e);
          alert(
            "Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support."
          );
        });
    }
  }

  const handleUpdateResetEmail = (event) => {
    setResetEmail(event.target.value);
  };
  const handleUpdateValidationCode = (event) => {
    setValidationCode(event.target.value);
  };
  const handleUpdateUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleUpdatePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleUpdateResetPassword = (event) => {
    setResetPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const redirectUrl = `/course-dashboard/`;
    handleLogin({ username, password, remember, redirectUrl });
  };

  const handleInitialFormTranslate = () => {
    if (typeof window !== "undefined") {
      const loginForm = document.querySelector(".login .login-form");
      const resetForm = document.querySelector(".login .reset-form");

      gsap.to(loginForm, { x: "-150%" });
      gsap.to(resetForm, { x: "-0%", delay: 0.3 });
    }
  };
  const resetFormSlidesToInitialState = () => {
    if (typeof window !== "undefined") {
      const loginForm = document.querySelector(".login .login-form");
      const resetForm = document.querySelector(".login .reset-form");
      setCurrentSlideIndex(0);
      gsap.to(resetForm, { x: "150%", delay: 0.3 });
      gsap.to(loginForm, { x: "0%", delay: 0.6 });
    }
  };
  const handleResetAndTransition = () => {
    reset(resetEmail);
    setCurrentSlideIndex(1);
  };
  const handleValidateAndTransition = () => {
    validate(validationCode);
    setCurrentSlideIndex(2);
  };
  const handlePasswordAndTransition = () => {
    setNewPassword(validationCode, resetPassword);
    resetFormSlidesToInitialState();
  };

  const closeNav = () => {
    setIsLoginActive(false);
    resetFormSlidesToInitialState();
  };
  const handleNav = () => {
    const targets = Array.from(document.querySelectorAll(".offscreen-translator"));
    const nav = document.querySelector("#mobileNav");

    const navs = Array.from(document.querySelectorAll(".navs"));

    gsap.to(targets, { duration: 0.2, ease: "power2.out" });
    gsap.to(targets, {
      x: "0%",
      delay: 0.2,
      duration: 0.75,
      ease: "power2.out",
    });
    gsap.to("body", { overflowX: "visible" });
    if (window.innerWidth >= 1024) {
      gsap.to(navs, { opacity: 0, x: 0, duration: 0.6, pointerEvents: "none" });
    } else {
      const newNavs = navs.filter((nav) => nav.id !== "mobileNav");
      newNavs.push("#login");
      gsap.to(newNavs, {
        opacity: 0,
        x: "-100%",
        duration: 0.6,
        pointerEvents: "none",
      });
      gsap.to("#mobileNav", {
        x: "0",
        duration: 0,
        pointerEvents: "none",
        delay: 0.5,
      });
    }
    targets.forEach((target) => target.classList.remove("active"));
    setIsMenuActive(false);
    setIsLoginActive(false);
    resetFormSlidesToInitialState();
  };

  useEffect(() => {
    gsap.to(".login .reset-form .form-styles", {
      x: `-${150 * currentSlideIndex}%`,
    });
  }, [currentSlideIndex]);

  useEffect(() => {
    if (typeof window !== "undefined" && isLoginActive === true) {
      const targets = Array.from(document.querySelectorAll(".offscreen-translator"));
      const login = Array.from(document.querySelectorAll("#login"));
      targets.forEach((target) => target.classList.add("active"));
      gsap.to(login, { opacity: 1, pointerEvents: "all", duration: 0 });
      if (window.innerWidth >= 1024) {
        gsap.to(targets, { x: "-30vw" });
      } else {
        gsap.to(targets, { x: "-100vw" });
      }
    }
    if (typeof window !== "undefined" && isLoginActive === false) {
      const targets = Array.from(document.querySelectorAll(".offscreen-translator"));
      const login = Array.from(document.querySelectorAll("#login"));
      targets.forEach((target) => target.classList.remove("active"));
      gsap.to(targets, { x: "0" });
      gsap.to(login, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0,
        delay: 1,
      });
    }
  }, [isLoginActive]);

  return (
    <Styling id="login" className="login">
      <button className="closeLogin" onClick={() => closeNav()} aria-label="Close Navigation">
        <GrClose />
      </button>
      <button
        className="closeLoginMobile"
        onClick={() => handleNav()}
        aria-label="Close Navigation">
        <GrClose />
      </button>
      <div className="title">
        <Arrow /> Login
      </div>
      <div className="forms-wrapper">
        <form
          method="post"
          className="login-form"
          onSubmit={(event) => {
            handleSubmit(event);
          }}>
          <div className="form-styles">
            <div className="validation-message">Incorrect username and/or password</div>
            <input
              type="text"
              placeholder="Username/Email"
              className="col-1"
              name="username"
              onChange={(e) => handleUpdateUsername(e)}
              value={username}
            />
            <input
              type="password"
              placeholder="Password"
              className="col-1"
              name="password"
              onChange={(e) => handleUpdatePassword(e)}
            />
            <div className="checkbox-wrapper">
              <span>
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  onClick={() => setRemember(true)}
                />
                <span></span>
              </span>
              <label htmlFor="remember-me">Remember Me</label>
            </div>
            <div className="buttons">
              <span className="fakeButton" onClick={() => handleInitialFormTranslate()}>
                Forgot your password
              </span>
            </div>

            <input
              type="submit"
              className={`submit_button realCapsuleButton capsule_button black ${
                username === undefined ||
                password === undefined ||
                username === "" ||
                password === ""
                  ? "disabled"
                  : ""
              }`}
              value={"Login"}
            />

            <div className="submit_button capsule_button black fakeCapsuleButton">
              Logging in <AiOutlineLoading3Quarters className="svgWrap" />
            </div>
          </div>
        </form>
        <div className="reset-form fakeForm">
          <div className="form-styles">
            <div className="form-step-1 form-step">
              <input
                type="text"
                placeholder="Email address"
                onChange={(e) => handleUpdateResetEmail(e)}
                className="reset-email col-1"
              />
              <button
                onClick={() => handleResetAndTransition()}
                className="send-reset-form submit_button capsule_button black col-1">
                Send reset code
              </button>
            </div>
            <div className="form-step-2 form-step">
              <input
                type="text"
                placeholder="Validation code"
                onChange={(e) => handleUpdateValidationCode(e)}
                className="reset-validation col-1"
              />
              <button
                onClick={() => handleValidateAndTransition()}
                className="send-reset-form submit_button capsule_button black col-1">
                Validate code
              </button>
            </div>
            <div className="form-step-3 form-step">
              <input
                type="password"
                placeholder="Enter new password"
                onChange={(e) => handleUpdateResetPassword(e)}
                className="reset-password col-1"
              />
              <button
                onClick={() => handlePasswordAndTransition()}
                className="send-reset-form submit_button capsule_button black col-1">
                Reset password
              </button>
            </div>
          </div>
        </div>
      </div>
    </Styling>
  );
}
