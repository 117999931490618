const variables = {
  footerHeight: "0px",
  white: "#ffffff",
  black: "#000000",
  grey: "#9C9C9C",
  grey_mid: "#C9C9C9",
  grey_light: "#F3F3F3",
  green: "#BBEDE4",
  font_body: "telegraf--regular",
};

const cssVariables = () => {
  let cssVariables = "";

  Object.entries(variables).forEach((variable) => {
    const [key, value] = variable;
    // //console.log(key, value);
    cssVariables += `--${key}: ${value}; `;
  });

  return cssVariables;
};

export { cssVariables };
