import styled from "styled-components";

const Styling = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  pointer-events: none;

  .cookie-banner {
    width: 100%;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    pointer-events: all;

    &.hidden {
      transform: translateY(100%);
      pointer-events: none;
    }

    &.visible {
      transform: translateY(0%);
    }

    .cookie-banner-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.9);
      /* transform: translateY(-100%); */
      pointer-events: all;

      .text {
        /* margin-bottom: 1rem; */
      }

      .buttons {
        display: flex;
        gap: 2rem;
      }

      .text-inner {
        max-width: 1024px;
        line-height: 1.5em;
      }

      @media only screen and (max-width: 550px) {
        display: block;
        text-align: center;
      }
    }
  }
`;

export { Styling };
