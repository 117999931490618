
import styled from 'styled-components'
import SubmitArrow from '../../../images/arrow-right.svg';

const Styling = styled.footer`
background:var(--black);
color:var(--white);
font-size:clamp(1.5rem, 1vw, 4rem);
position: relative;
z-index: 2000 !important;
width: 100%;
overflow: hidden;

    button,
    a{
    color:var(--white);
    text-decoration: none;
    position: relative;
    }


    .upper{
    padding:clamp(1rem, 8vw, 12rem) clamp(2rem, 4vw, 8rem);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    
        button{
        padding:0;
        border:0;
        color:inherit;
        }

        button,
        a{
        position: relative;

            &:after{
            content:"";
            width:0.7rem;
            height:0.7rem;
            background:var(--white);
            position: absolute;
            border-radius: 100%;
            transform:scale(0);
            ${({theme}) => theme.easing('0.25')}  
            }

            &[aria-current="page"]:after,
            &:hover:after{
            transform: scale(1);
            }
        }

        .col2{
            @media only screen and (max-width: 1024px) {
            grid-column-start: 1;
            grid-column-end: 3;  
            }
        }
        .col3{
            @media only screen and (max-width: 1024px) {
            grid-column-start: 4;
            grid-column-end: 8;  
            }
            @media only screen and (max-width: 460px) {
            grid-column-start: 5;
            grid-column-end: 8;  
            }
        }

        .col:first-child{
        grid-column-start: 1;
        grid-column-end: 8;
        margin-bottom: 4rem;

            @media only screen and (min-width: 1024px) {
            grid-column-end: 4;
            margin-bottom: 0;
            }

        }
        .col:last-child{
        grid-column-start: 1;
        grid-column-end: 8;
        margin-top:4rem;

            @media only screen and (min-width: 1024px) {
            margin-top:0;
            grid-column-start: 6;
            grid-column-end: 8;
            }

        }

        label{
        font-size:clamp(2rem, 2.5vw, 3rem); 
        text-transform:uppercase;
        letter-spacing: -0.04em;
        margin-bottom: 2rem;
        position: relative;
        display: block;
        }
        
        .col1 a,
        .col1 button{
        font-size:clamp(4rem, 4vw, 6rem); 
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        letter-spacing: -0.04em;

            &:after{
            width:1.5rem;
            height:1.5rem;
            top:calc(50% - 0.75rem);
            right:-3rem;

                @media only screen and (min-width: 1024px) {        
                right:initial;
                left:-3rem;
                }
            }

        }

        .col2,
        .col3{
            ul{

                li{
                margin-bottom: 1rem;
                }                
            }

            a:after{
            right:-1.5rem;
            top:calc(50% - 0.35rem);

                @media only screen and (min-width: 1024px) {        
                right:initial;
                left:-1.5rem;
                }

            }

        }

        .col4{
            .col-4-text{
            margin-bottom: 2rem;
            }

            .col-4-form{
            position: relative;

                input[type="email"]{
                background:none;
                position: relative;
                border:0;
                padding:2rem 4rem 2rem 0;
                width: 100%;
                border-bottom: 1px solid var(--grey);
                color:var(--white);
                ${({theme}) => theme.easing('0.5')}  

                    &::placeholder{
                        color:var(--grey);
                        ${({theme}) => theme.easing('0.5')}  
                    }

                    &:focus{
                    outline:none;
                    border-bottom:1px solid var(--white);

                        &::placeholder{
                        color:var(--white);
                        }
                    }
                }

                input[type="submit"]{
                position:absolute;
                top:50%;
                transform: translateY(-50%);
                right: 0;
                text-indent:-99999999px;
                background-color:none;
                background:url(${SubmitArrow});
                background-repeat:no-repeat;
                border:0;
                padding:0;
                width: 2rem;
                height: 2rem;
                -webkit-appearance:none;
                border-radius: 0;
                cursor: pointer;
                }
            }
        }
    }

    .lower{
    padding:0  clamp(2rem, 4vw, 8rem) clamp(1rem, 4vw, 12rem) clamp(2rem, 4vw, 8rem);
    display: flex;
    justify-content: space-between;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    flex-wrap: wrap;

        .copy{
        width:auto;
        grid-column-start: 1;
        grid-column-end: 4;
        font-size:clamp(1rem, 0.7vw, 2rem);
        order:2;

            @media only screen and (min-width: 640px) {
            order:1;
            }
           
            span{
            opacity: 0.5;
            ${({theme}) => theme.easing('0.545')}  
                &:hover{
                opacity: 1;
                }
            }
        }

        .socials{
        display: inline-flex;
        grid-column-start: 6;
        grid-column-end: 8;
        order:1;
        width: 100%;
        margin-bottom: 4rem;
        justify-content: space-between;


            @media only screen and (min-width: 640px) {
            order:2;
            margin-bottom: 0;
            width: auto;
                justify-content: unset;
            }

            li{
            font-size:clamp(2rem, 1.4vw, 4rem);
            

    
            @media only screen and (min-width: 640px) {
                margin-right:0;
                margin-left:4rem;
            }
             

                a{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                ${({theme}) => theme.easing('0.25')} 

                    span{
                    z-index:10;
                    }

                    &:after{
                    content:"";
                    width:200%;
                    padding-bottom: 200%;
                    background:var(--white);
                    position: absolute;
                    left:0;
                    top:0;
                    border-radius: 100%;
                    transform:scale(0) translate(-50%, -50%);
                    ${({theme}) => theme.easing('0.25')}  
                    }

                    &[aria-current="page"],
                    &:hover{
                    color:var(--black);

                        &:after{
                        transform: scale(1) translate(-25%, -25%);
                        }
                    }
                }

            }

        }
    }


`

export { Styling }