import React from "react";

export default function RegisterInterestScrollButton({ hash, isFixedInFooter, borderColor, text }) {
  const scrollToView = (e) => {
    let hashval = e.target.closest("a").getAttribute("href");
    let target = document.querySelector(hashval);

    var elementPosition = target.offsetTop;

    window.scrollTo({
      top: elementPosition - 150, //add your necessary value
      behavior: "smooth", //Smooth transition to roll
    });

    e.preventDefault();
  };

  return (
    <a
      href={hash}
      onClick={(e) => scrollToView(e)}
      className={`capsule_button ${
        isFixedInFooter && "enquire_button_fixed"
      } flashButton--${borderColor}   offscreen-translator`}>
      <span>{text}</span>
    </a>
  );
}
