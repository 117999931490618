import gsap from "gsap";

const handleNav = (e, setIsMenuActive, isMenuActive, menu) => {
  const targets = Array.from(
    document.querySelectorAll(".offscreen-translator")
  );
  const nav = document.querySelector(menu);
  const navs = Array.from(document.querySelectorAll(".navs"));

  if (typeof window !== "undefined" && isMenuActive === false) {
    let x_trans;
    if (window.innerWidth >= 1024) {
      x_trans = "35vw";
    } else {
      x_trans = "100vw";
    }

    // gsap.to('#___gatsby', {backgroundColor:'black'})
    gsap.to(navs, { opacity: 0, duration: 0, pointerEvents: "none" });
    gsap.to(nav, { opacity: 1, duration: 0.001, pointerEvents: "all", x: 0 });
    targets.forEach((target) => target.classList.add("active"));
    gsap.to(targets, { x: x_trans, duration: 0.75, ease: "power2.out" });
    gsap.to(targets, { delay: 0.75, duration: 0.75, ease: "power2.out" });
    gsap.to("body", { overflowX: "hidden" });
    setIsMenuActive(true);
  } else {
    let x_trans;

    gsap.to(targets, { duration: 0.2, ease: "power2.out" });
    gsap.to(targets, {
      x: "0%",
      delay: 0.2,
      duration: 0.75,
      ease: "power2.out",
    });
    gsap.to("body", { overflowX: "visible" });
    if (window.innerWidth >= 1024) {
      gsap.to(navs, { opacity: 0, x: 0, duration: 0.6, pointerEvents: "none" });
    } else {
      const newNavs = navs.filter((nav) => nav.id !== "mobileNav");
      gsap.to(newNavs, {
        opacity: 0,
        x: "-100%",
        duration: 0.6,
        pointerEvents: "none",
      });
      gsap.to("#mobileNav", {
        x: "0",
        duration: 0,
        pointerEvents: "none",
        delay: 0.5,
      });
    }
    targets.forEach((target) => target.classList.remove("active"));
    setIsMenuActive(false);
  }
};

// const toggleSecondaryMenu = (
//     e,
//     isMerchMenuActive,
//     setIsMerchMenuActive,
//     isSamplePackMenuActive,
//     setIsSamplePackMenuActive,
//     isSecondaryMenuActive,
//     setIsSecondaryMenuActive
// ) => {
//     const targets = Array.from(document.querySelectorAll('.offscreen-translator'))
//     const clickTarget = e.target.closest('button').classList;

//     if(typeof window !== 'undefined' && isSecondaryMenuActive === false){
//         //console.log(isSamplePackMenuActive, isMerchMenuActive)
//         gsap.to(targets, {x:'70%', duration:0.75, ease: "power2.out"})
//         gsap.to('.secondarynav', {x:'100%', duration:0.75, ease: "power2.out"})
//         // setIsSecondaryMenuActive(true)

//         if(isMerchMenuActive === false && clickTarget.contains('samplePacks')){
//             gsap.to('.samplePackNav ul', {opacity:1, duration:0.75, pointerEvents:'all'})
//             setIsSamplePackMenuActive(true)
//         }
//         if(isMerchMenuActive === true && clickTarget.contains('samplePacks')){
//             gsap.to('.merchNav ul', {opacity:0, duration:0.75, pointerEvents:'none'})
//             gsap.to('.samplePackNav ul', {opacity:1, duration:0.75, delay:0.5, pointerEvents:'all'})
//             setIsSamplePackMenuActive(true)
//             setIsMerchMenuActive(false)
//         }
//         if(isSamplePackMenuActive === false && clickTarget.contains('merch')){
//             gsap.to('.merchNav ul', {opacity:1, duration:0.75, pointerEvents:'all'})
//             setIsMerchMenuActive(true)
//         }
//         if(isSamplePackMenuActive === true && clickTarget.contains('merch')){
//             gsap.to('.samplePackNav ul', {opacity:0, duration:0.75, pointerEvents:'none'})
//             gsap.to('.merchNav ul', {opacity:1, duration:0.75, delay:0.5, pointerEvents:'all'})
//             setIsMerchMenuActive(true)
//             setIsSamplePackMenuActive(false)
//         }
//     }
// }

export {
  handleNav,
  // toggleSecondaryMenu
};
