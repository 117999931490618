require("./src/styles/preloader.scss");
require("./src/fonts/typography.css");
require("normalize.css");
require("./src/styles/reset.css");
const LogRocket = require("logrocket");

exports.onClientEntry = () => {
  //console.log('on client entry')
  LogRocket.init("nvb46s/toolroom-academy");
};
