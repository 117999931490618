import styled from "styled-components";

const Styling = styled.section`
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999;
  pointer-events: none;
  visibility: hidden;

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 2rem;
    color: var(--black);
    cursor: pointer;
    z-index: 999999999999999999;
  }

  .iframe-wrapper {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
`;

export { Styling };
