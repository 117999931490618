import styled from 'styled-components'

const Styling = styled.div`
height:100%;
position: fixed;
top:0;
left:0;
opacity:0;
pointer-events:none;
width: 100vw;
opacity: 0;
pointer-events: none;
transform:translateX(-100%);

    @media only screen and (min-width: 1024px) {
    width: 35vw;
    transform:translateX(0%);
    }

    .main-inner{
    width: 100%;
    height:100%;
    z-index: 100;
    position: absolute;
    display: flex;
    background:var(--white);
    flex-direction: column;
    justify-content: space-between;
    padding:4rem;
    align-items: flex-start;
    }

    .shopNav--close{
    cursor: pointer;
    padding:2rem;
    margin:-2rem;

        button{
        border:0;
        padding:0;
        }

        svg{
            ${({theme}) => theme.easing('0.5')}  
        }

        &:hover svg{
        transform: rotate(90deg);
        }

    }

    .secondaryShopNav{
    background:var(--grey_light);
    position:absolute;
    top:0;
    width: 100%;
    height:100%;

        ul{
        opacity: 0;
        width: 100%;
        position:absolute;
        top:0;
        width: 100%;
        height:100%;
        display: flex;
        justify-content: center;
        padding:4rem;
        flex-wrap: wrap;
        flex-direction: column;
        pointer-events: none;
        }
    }

    .merchNav ul,
    .samplePackNav ul,
    .shopNav{
    width: 100%;

        li{
        width: 100%;
        border-bottom:1px solid var(--grey_mid);
        position: relative;
            
            a{
            color:inherit;
            text-decoration:none;
            }
            
            button{
            padding:0;
            border:0;
            }

            a,
            button{
            width: 100%;
            text-align:left;
            display: flex;
            padding:2rem 0;
            position: relative;
            justify-content: space-between;
            font-size:clamp(2rem, 2vw, 5rem);
            text-transform:uppercase;
            align-items: center;
            ${({theme}) => theme.easing('0.5')}  

                &:hover{
                padding-left:2rem;
                padding-right:2rem;
                }

            }
        }

    }

`

export { Styling }