import React, { useEffect, useRef, useState } from "react";
import Logo from "../../../images/logo.inline.svg";
import { Link } from "gatsby";
import { Styling } from "./styled.js";
import gsap from "gsap";
import { useStaticQuery, graphql } from "gatsby";
import Circle from "../../../images/circle.svg";
import { handleNav } from "../../../animations/handleNav";
import MobileNav from "../MobileNav";
import { isLoggedIn, logout } from "../../../utils/auth";
import styled from "styled-components";

const ItemCount = styled.div`
  background: url(${Circle});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
`;

export default function Header({
  className,
  setIsMenuActive,
  isMenuActive,
  isMerchMenuActive,
  setIsMerchMenuActive,
  isSamplePackMenuActive,
  setIsSamplePackMenuActive,
  setIsLoginActive,
  isLoginActive,
  snipcart,
}) {
  const showLoginModal = (e) => {
    setIsLoginActive(!isLoginActive);
  };

  let doit;
  const headerRef = useRef();

  const forceMixBlendModeOnHeader = () => {
    clearTimeout(doit);
    headerRef.current.style.mixBlendMode = "normal";
    doit = setTimeout(function () {
      headerRef.current.style.mixBlendMode = "difference";
    }, 100);
  };

  useEffect(() => {
    forceMixBlendModeOnHeader();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", forceMixBlendModeOnHeader);
    }
  });

  return (
    <>
      <Styling className={className} ref={headerRef}>
        <div className="desktop desktop-wrapper">
          <Link to="/">
            <Logo className="logo" />
          </Link>
          <nav className="nav">
            <ul>
              <li>
                <button
                  onClick={(e) =>
                    handleNav(
                      e,
                      setIsMenuActive,
                      isMenuActive,
                      "#programmesNav"
                    )
                  }
                  className="fake_link"
                >
                  Programmes
                </button>
              </li>
              <li>
                <button
                  onClick={(e) =>
                    handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")
                  }
                  className="fake_link"
                >
                  Shop
                </button>
              </li>
              <li>
                <Link to="/student-success/">Testimonials</Link>
              </li>
              <li>
                <Link to="/features/">Features</Link>
              </li>
            </ul>
          </nav>
          <ul className="cart_login">
            {isLoggedIn() ? (
              <li>
                <Link to="/course-dashboard/">My Courses</Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <button
                className="login"
                onClick={(e) => {
                  if (isLoggedIn()) {
                    logout();
                  } else {
                    showLoginModal(e);
                  }
                }}
              >
                <span className="text">
                  {isLoggedIn() ? "Logout" : "Login"}
                </span>
              </button>
            </li>
            <li>
              <button className="cart snipcart-checkout">
                <span className="text">Cart</span>
                {snipcart.cartQuantity !== 0 ? (
                  <ItemCount className="item_count">
                    {snipcart.cartQuantity}
                  </ItemCount>
                ) : (
                  ""
                )}
              </button>
            </li>
          </ul>
        </div>
        <div className="mobile-header">
          <Link to="/">
            <Logo className="logo" />
          </Link>

          <div className="cart_and_toggle_wrap">
            <button className="cart snipcart-checkout">
              {snipcart.cartQuantity !== 0 ? (
                <ItemCount className="item_count">
                  {snipcart.cartQuantity}
                </ItemCount>
              ) : (
                ""
              )}
            </button>
            <div
              className="navToggle"
              onClick={(e) =>
                handleNav(e, setIsMenuActive, isMenuActive, "#mobileNav")
              }
            >
              <div className="line top"></div>
              <div className="line middle"></div>
              <div className="line bottom"></div>
            </div>
          </div>
        </div>
      </Styling>

      <MobileNav
        setIsMenuActive={setIsMenuActive}
        isMenuActive={isMenuActive}
        isMerchMenuActive={isMerchMenuActive}
        setIsMerchMenuActive={setIsMerchMenuActive}
        isSamplePackMenuActive={isSamplePackMenuActive}
        setIsSamplePackMenuActive={setIsSamplePackMenuActive}
        setIsLoginActive={setIsLoginActive}
        isLoginActive={isLoginActive}
        snipcart={snipcart}
        isLoggedIn={isLoggedIn}
        logout={logout}
        showLoginModal={showLoginModal}
        handleNav={handleNav}
      />
    </>
  );
}
