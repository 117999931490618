import React from 'react'
import { Styling } from './styled.js'
import {Link} from 'gatsby'
import {GrClose} from 'react-icons/gr'
import {BsArrowRightShort} from 'react-icons/bs'
import {handleNav} from '../../../animations/handleNav'
import { useStaticQuery, graphql } from "gatsby"

export default function ProgrammesNav({
    setIsMenuActive, 
    isMenuActive,
}) {
    const data = useStaticQuery(graphql`
    {
        menu: wpMenu(name: {eq: "Programmes Fly-in Nav"}) {
            menuItems {
              nodes {
                label
                url
              }
            }
          }
    }
  `)
    return (
        <Styling id="programmesNav"  className="navs">
            <div className="main-inner">
                <div className="shopNav--close">
                    <button onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#shopNav') } aria-label="Close Navigation">
                        <GrClose />
                    </button>
                </div>
                <nav className="shopNav">
                    <ul>
                        {
                            data.menu.menuItems.nodes.map((menuItem, index) => <li key={`${index}_${menuItem.label}`}><Link  onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#shopNav')  } to={menuItem.url}>{menuItem.label} <BsArrowRightShort /></Link></li>)
                        }
                    </ul>
                </nav>
                <Link to="/programmes/" className="capsule_button white"  onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#shopNav')  }>View all</Link>
            </div>
        </Styling>
    )
}

