import React, {useRef, useEffect, useState} from 'react'
import { Link } from 'gatsby'
import { Styling } from './styled.js'
import { useStaticQuery, graphql } from "gatsby"
import {FaFacebook, FaSpotify} from 'react-icons/fa'
import {AiFillInstagram, AiOutlineTwitter, AiFillApple} from  "react-icons/ai"
import {SiDeezer} from 'react-icons/si'
import {handleNav} from '../../../animations/handleNav'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default function Footer({
    className, 
    setIsMenuActive, 
    isMenuActive, 
    isMerchMenuActive, 
    setIsMerchMenuActive, 
    isSamplePackMenuActive, 
    setIsSamplePackMenuActive,
    setIsLoginActive,
    isLoginActive
}) {
    const data = useStaticQuery(graphql`
        {
            menu1: wpMenu(name: {eq: "Main Menu"}) {
                menuItems {
                    nodes {
                        url
                        label
                    }
                }
            }
            menu2: wpMenu(name: {eq: "Footer Menu 1"}) {
                menuItems {
                    nodes {
                        url
                        label
                    }
                }
            }
            menu3: wpMenu(name: {eq: "Footer Menu 2"}) {
                menuItems {
                    nodes {
                        url
                        label
                    }
                }
            }
            footer: wp {
                siteGeneralSettings {
                    acf_siteSettings {
                        appleMusic
                        deezer
                        facebook
                        instagram
                        spotify
                        twitter
                        newsletterText
                    }
                }
            }
        }
    `)

    const menu1 = data.menu1.menuItems.nodes
    const menu2 = data.menu2.menuItems.nodes
    const menu3 = data.menu3.menuItems.nodes
    const {appleMusic, deezer, facebook, instagram, newsletterText, spotify, twitter} = data.footer.siteGeneralSettings.acf_siteSettings;

    const date = new Date();
    const year = date.getFullYear()

    const footerRef = useRef()

    const calculateFooterHeight = () => {
        if(typeof window !== 'undefined'){
            const footer = footerRef.current;
            const footerHeight = Math.ceil(footer.clientHeight.toFixed(2))
            document.documentElement.style.setProperty('--footerHeight', footerHeight + "px");
        }
    }

    useEffect(() => {
        
        if(typeof window !== 'undefined'){

            window.addEventListener('load', calculateFooterHeight)
            window.addEventListener('resize', calculateFooterHeight)
            return () => {
               window.removeEventListener('resize', calculateFooterHeight)
            }
        }
    }, [footerRef])

    const [email, setEmail] = useState()

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        addToMailchimp(email)
        .then(data => {
            // I recommend setting data to React state
            // but you can do whatever you want (including ignoring this `then()` altogether)
            if(data.result === 'success'){
                alert(data.msg)
            }
        })
    }
    
    
    return (
        <Styling className={className} ref={footerRef}>
            <div className="upper">
                <div className="col1 col">
                    <nav className="nav" id="nav-1">
                        <ul>
                            {
                                menu1.map(
                                    (item, index) => {
                                        let link 
                                        if(item.url === '#shop'){
                                            link = <li key={`footer1-link_${index}`}><button className="fake_link" onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#shopNav') }>{item.label}</button></li>
                                        }
                                        else if(item.url === '#programmes'){
                                            link = <li key={`footer1-link_${index}`}><button className="fake_link" onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, '#programmesNav') }>{item.label}</button></li>
                                        }else{
                                            link =  <li key={`footer1-link_${index}`}><Link to={item.url}>{item.label}</Link></li>
                                        }
        
                                        return link
                                    }
                                )
                            }
                        </ul>
                    </nav>
                </div>
                <div className="col2 col">
                    <nav className="nav" id="nav-2">
                        <label htmlFor="nav-2">About</label>
                        <ul>
                            {
                                menu2.map(
                                    (item, index) => {
                                        let link =  <li key={`footer2-link_${index}`}><Link to={item.url}>{item.label}</Link></li>
                                        
                                        return link
                                    }
                                )
                            }
                            <li><a href="mailto:info@toolroomacademy.com">Contact us</a></li>
                        </ul>
                    </nav>
                </div>
                <div className="col3 col">
                    <nav className="nav" id="nav-3">
                        <label htmlFor="nav-3">Legal</label>
                        <ul>
                            {
                                menu3.map(
                                    (item, index) => {
                                        let link =  <li key={`footer3-link_${index}`}><Link to={item.url}>{item.label}</Link></li>
                                        
                                        return link
                                    }
                                )
                            }
                        </ul>
                    </nav>
                </div>
                <div className="col4 col" id="col-4">
                    <label htmlFor="col-4">Newsletter Signup</label>
                    <div className="col-4-text">{newsletterText}</div>
                    <form className="col-4-form"  onSubmit={(e) => handleSubmit(e)}>
                        <input type="email" onChange={(e) => handleEmail(e)} required placeholder="Email address" />
                        <input type="submit" value="Submit" title="Submit form"/>
                    </form>
                </div>
            </div>
            <div className="lower">
                <div className="copy"><span>&copy; Toolroom Academy {year} All rights reserved</span></div>
                <ul className="socials">
                    <li>
                        <a href={facebook}>
                            <span><FaFacebook /></span>
                        </a>
                    </li>
                    <li>
                        <a href={instagram}>
                            <span><AiFillInstagram /></span>
                        </a>
                    </li>
                    <li>
                        <a href={twitter}>
                            <span><AiOutlineTwitter /></span>
                        </a>
                    </li>
                    <li>
                        <a href={spotify}>
                            <span><FaSpotify /></span>
                        </a>
                    </li>
                    <li>
                        <a href={appleMusic}>
                            <span><AiFillApple /></span>
                        </a>
                    </li>
                    <li>
                        <a href={deezer}>
                            <span><SiDeezer /></span>
                        </a>
                    </li>
                </ul>
            </div>
        </Styling>
    )
}
