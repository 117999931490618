import React from "react";
import { Styling } from "./styled.js";
import { Link } from "gatsby";
import { GrClose } from "react-icons/gr";
import { BsArrowRightShort } from "react-icons/bs";
import { handleNav } from "../../../animations/handleNav";
import { useStaticQuery, graphql } from "gatsby";

export default function ShopNav({
  setIsMenuActive,
  isMenuActive,
  isMerchMenuActive,
  setIsMerchMenuActive,
  isSamplePackMenuActive,
  setIsSamplePackMenuActive,
  isSecondaryMenuActive,
  setIsSecondaryMenuActive,
}) {
  const data = useStaticQuery(graphql`
    {
      samplePackNavs: allWpSamplePackCategory {
        nodes {
          name
          count
          slug
        }
      }
      merchNavs: allWpMerchCategory {
        nodes {
          count
          name
          slug
        }
      }
    }
  `);
  return (
    <Styling id="shopNav" className="navs">
      <div className="main-inner">
        <div className="shopNav--close">
          <button
            onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}
            aria-label="Close Navigation">
            <GrClose />
          </button>
        </div>
        <nav className="shopNav">
          <ul>
            <li key={"shopNav__1"}>
              {/* <button className="samplePacks" onClick={(e) => toggleSecondaryMenu(e, isMerchMenuActive, setIsMerchMenuActive, isSamplePackMenuActive, setIsSamplePackMenuActive, isSecondaryMenuActive, setIsSecondaryMenuActive)}>Sample Packs <BsArrowRightShort /></button> */}
              <Link
                to="/shop/sample-packs"
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
                Sample Packs <BsArrowRightShort />
              </Link>
            </li>
            <li key={"shopNav__2"}>
              <Link
                to="/shop/infinite"
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
                Plugins <BsArrowRightShort />
              </Link>
            </li>
            <li key={"shopNav__2b"}>
              <Link
                to="/templates"
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
                Templates <BsArrowRightShort />
              </Link>
            </li>
            <li key={"shopNav__3"}>
              {/* <button className="merch" onClick={(e) => toggleSecondaryMenu(e, isMerchMenuActive, setIsMerchMenuActive, isSamplePackMenuActive, setIsSamplePackMenuActive, isSecondaryMenuActive, setIsSecondaryMenuActive)}>Merch <BsArrowRightShort /></button> */}
              <Link
                to="/shop/merch"
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
                Merch <BsArrowRightShort />
              </Link>
            </li>
            <li key={"shopNav__4"}>
              <Link
                to="/programmes/on-demand"
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
                Tutorials <BsArrowRightShort />
              </Link>
            </li>
          </ul>
        </nav>
        <Link
          to="/shop/"
          className="capsule_button white"
          onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}>
          View all
        </Link>
      </div>

      <div className="secondaryShopNav">
        <div className="merchNav">
          <ul>
            {data.merchNavs.nodes.map((nav, index) => {
              let link;
              if (nav.count > 0) {
                link = (
                  <li key={`merchNavs_${index}`}>
                    <Link
                      onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}
                      to={`/shop/merch/${nav.slug}`}>
                      {nav.name} <BsArrowRightShort />
                    </Link>
                  </li>
                );
              }
              return link;
            })}
            <li key={`samplePackNavsB_${data.merchNavs.nodes.length + 1}`}>
              <Link
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}
                to={`/shop/merch`}>
                All merch <BsArrowRightShort />
              </Link>
            </li>
          </ul>
        </div>
        <div className="samplePackNav">
          <ul>
            {data.samplePackNavs.nodes.map((nav, index) => {
              let link;
              if (nav.count > 0) {
                link = (
                  <li key={`samplePackNavs_${index}`}>
                    <Link
                      onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}
                      to={`/shop/sample-packs/${nav.slug}`}>
                      {nav.name} <BsArrowRightShort />
                    </Link>
                  </li>
                );
              }
              return link;
            })}
            <li key={`samplePackNavsC_${data.samplePackNavs.nodes.length + 1}`}>
              <Link
                onClick={(e) => handleNav(e, setIsMenuActive, isMenuActive, "#shopNav")}
                to={`/shop/sample-packs`}>
                All sample packs <BsArrowRightShort />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Styling>
  );
}
