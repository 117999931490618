import React, { useEffect, useState } from "react";
import { Styling } from "./styled.js";
import { gtag, install } from "ga-gtag";
import { Link } from "gatsby";
import gsap from "gsap";
import FacebookPixel from "./FacebookPixel.js";
import GoogleTagManager from "./GoogleTagManager.js";

export default function ConsentBanner({ data }) {
  const [cookiePolicySubmitted, setCookiePolicySubmitted] = useState("false");
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const { Snipcart } = typeof window !== "undefined" ? window : "";

  const handleSnipcartGtagEvents = () => {
    if (Snipcart !== undefined || Snipcart !== "undefined") {
      Snipcart.events.on("cart.confirmed", (cartConfirmResponse) => {
        // console.log(cartConfirmResponse);
        const cartItems = cartConfirmResponse.items.items;

        cartItems.map((cartItem) => {
          if (cartItem.categories.includes("onDemand")) {
            console.log("order contains onDemand product");
            gtag("event", "conversion", {
              send_to: "AW-11012634547/offGCLTNpIsYELPvnYMp",
              value: 120.0,
              currency: "GBP",
              transaction_id: `onDemand_${cartConfirmResponse.invoiceNumber}`,
            });
          }
          if (cartItem.categories.includes("plugin")) {
            console.log("order contains Plugin product");
            gtag("event", "conversion", {
              send_to: "AW-11012634547/sQsECLfNpIsYELPvnYMp",
              value: 49.99,
              currency: "GBP",
              transaction_id: `plugin_${cartConfirmResponse.invoiceNumber}`,
            });
          }
          if (cartItem.categories.includes("merch")) {
            console.log("order contains Merch product");
            gtag("event", "conversion", {
              send_to: "AW-11012634547/YobHCL3NpIsYELPvnYMp",
              value: 24.99,
              currency: "GBP",
              transaction_id: `merch_${cartConfirmResponse.invoiceNumber}`,
            });
          }
          if (cartItem.categories.includes("samplePack")) {
            console.log("order contains Sample Pack product");
            gtag("event", "conversion", {
              send_to: "AW-11012634547/1ccqCLrNpIsYELPvnYMp",
              value: 19.99,
              currency: "GBP",
              transaction_id: `samplePack_${cartConfirmResponse.invoiceNumber}`,
            });
          }
          if (cartItem.categories.includes("ableton-template")) {
            console.log("order contains Template product");
            gtag("event", "conversion", {
              send_to: "AW-11012634547/BPISCLjOpIsYELPvnYMp",
              value: 29.99,
              currency: "GBP",
              transaction_id: `abletotemplate_${cartConfirmResponse.invoiceNumber}`,
            });
          }
        });
      });
    }
  };

  const handleAcceptCookie = () => {
    if (typeof window !== "undefined") {
      console.log("tracking accepted");
      setCookiesAccepted(true);
      handleSnipcartGtagEvents();

      window.localStorage.setItem("cookieAccepted", "true");

      setTimeout(function () {
        setCookiePolicySubmitted("true");
      }, 1000);
    }
  };

  const handleDenyCookie = () => {
    console.log("tracking denied");
    window.localStorage.setItem("cookieAccepted", "false");
    setCookiePolicySubmitted("true");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const cookie = window.localStorage.getItem("cookieAccepted");
      if (cookie === "true") {
        setCookiesAccepted(true);
        setCookiePolicySubmitted("true");
        handleSnipcartGtagEvents();
      } else if (cookie === "false") {
        setCookiePolicySubmitted("true");
      }
    }
  });

  return (
    <Styling>
      <div className={`cookie-banner ${cookiePolicySubmitted === "true" ? "hidden" : ""}`}>
        <div className="cookie-banner-inner">
          <div className="text">
            <div className="text-inner">
              {cookiesAccepted === true && (
                <>
                  <FacebookPixel />
                  <GoogleTagManager />
                </>
              )}
              This website uses cookies We use cookies to personalise content and ads, to provide
              social media features and to analyse our traffic. We also share information about your
              use of our site with our social media, advertising and analytics partners who may
              combine it with other information that you’ve provided to them or that they’ve
              collected from your use of their services. You can read our cookie policy{" "}
              <Link to={"/cookies/"}>here</Link>
            </div>
          </div>
          <div className="buttons">
            <button onClick={() => handleDenyCookie()} className={"capsule_button small white"}>
              Deny
            </button>
            <button onClick={() => handleAcceptCookie()} className={"capsule_button small white"}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </Styling>
  );
}
