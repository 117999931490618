import { navigate } from "gatsby";
import closeLogin from "../utils/closeLogin";
import axios from "axios";

const isBrowser = typeof window !== `undefined`;

const loginFailed = () => {
  if (typeof window !== "undefined") {
    const form = document.querySelector(".form-styles");
    form.classList.add("login-failed");
    form.classList.remove("login-inProgress");
  }
};
const loginSuccess = () => {
  if (typeof window !== "undefined") {
    const form = document.querySelector(".form-styles");
    setTimeout(function () {
      form.classList.remove("login-failed");
      form.classList.remove("login-inProgress");
    }, 1000);
  }
};
const loginInProgress = () => {
  if (typeof window !== "undefined") {
    const form = document.querySelector(".form-styles");
    form.classList.add("login-inProgress");
  }
};

const handleIsAlumni = (userID, redirectUrl) => {
  if (typeof window !== "undefined") {
    const jwt = window.localStorage.userJwt;
    axios
      .get(
        `https://admin.toolroomacademy.com/wp-json/toolroom/v1/user/${userID}/is-alumni?jwt=${jwt}`
      )
      .then((r) => {
        if (r.data.data[0] === true) {
          //console.log('is alumni')
          setUser({
            userID: userID,
            alumni: true,
          });
          navigate();
        } else {
          //console.log('is NOT alumni')
          loginSuccess();
          alert("Sorry, you are not an alumni");
          setUser({
            userID: userID,
            alumni: false,
          });
        }
      })
      .catch((e) => {
        // alert('Sorry but it appears you are not Toolroom Alumni')
      });
  }
};

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {};
const getJwt = () =>
  window.localStorage.userJwt ? JSON.parse(window.localStorage.userJwt) : {};

const setUser = (user) =>
  (window.localStorage.gatsbyUser = JSON.stringify(user));

export const handleLogin = async ({
  username,
  password,
  remember,
  redirectUrl,
  checkAlumni,
}) => {
  if (!isBrowser) return false;
  const endpoint = `${process.env.GATSBY_CMS_URL}/?rest_route=/simple-jwt-login/v1/auth/validate&jwt=`;
  const url = `${
    process.env.GATSBY_CMS_URL
  }/?rest_route=/simple-jwt-login/v1/auth&email=${username.replace(
    "+",
    "%2B"
  )}&password=${encodeURIComponent(password)}`;

  loginInProgress();

  const response = await fetch(url, {
    // Send POST request to endpoint with username and password
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      // Store returned JWT
      const jwt = response.json();
      return jwt;
    })
    .then(function (jwt) {
      window.localStorage.userJwt = jwt.data.jwt;
      // Send JWT back for authentication
      fetch(`${endpoint}${jwt.data.jwt}`, {
        method: "GET",
        // headers: {
        //   'Authorization': `Bearer ${jwt.data.jwt}`
        // }
      })
        .then(function (auth) {
          // Receive authentication response
          const authResponse = auth.json();

          return authResponse;
        })
        .then(function (authResponse) {
          // Set User and navigate to course dashboard
          //console.log(authResponse)

          if (checkAlumni === true) {
            handleIsAlumni(authResponse.data.user.ID, redirectUrl);
          } else {
            navigate(redirectUrl);
            closeLogin();
            loginSuccess();
            setUser({
              userID: authResponse.data.user.ID,
            });
          }
          if (remember === true) {
            window.localStorage.rememberUser = username.replace("+", "%2B");
          }
        })
        .catch(function (error) {
          //console.log('error1', error)
          loginFailed();
        });
    })
    .catch(function (error) {
      loginFailed();
    });
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  const user = getUser();

  return !!user.userID;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback) => {
  if (!isBrowser) return;

  //console.log(`Ensuring the \`gatsbyUser\` property exists.`)
  setUser({});
  navigate("/");
};
