import { cssVariables } from "../styles/variables";
import { createGlobalStyle } from "styled-components";
import Arrow from "../images/arrow_down.svg";
import Play from "../images/play.svg";
import DD from "../images/dd.svg";

export const GlobalStyle = createGlobalStyle`

  :root{
    ${cssVariables}
  }

  *{
    box-sizing: border-box;
  }

  html{
    font-size: 62.5% !important;
    -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
    -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
    height: -webkit-fill-available;
  }
  body{

    font-size: 1.6rem;
    font-family: var(--font_body);
    font-weight:normal;
    background:var(--white);
  }



  a{
  color:inherit;
  }

  #___gatsby{
  /* background:var(--black); */
  }

  #gatsby-focus-wrapper{
  width: 100vw;
  overflow: hidden;
  }

  button{
  cursor: pointer;
  background:none;
  } 

  .zindex{
  z-index: 100;
  position:relative;  
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transform:none !important;
  }

  .course_dashboard--not-loggedin{
  width: 100%;
  height:calc(100vh - var(--footerHeight));
  background:var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:clamp(3rem, 3vw, 10rem);
  text-transform:uppercase;
  letter-spacing: -0.09em;
  flex-wrap: wrap;
  }

  .offscreen-translator{
  z-index: 100;
  position:relative;

    &:after{
    width:100%;
    height: 100%;
    content:"";
    position: absolute;
    top:0;
    left:0;
    z-index: 10;
    background:rgba(0,0,0,0.5);
    opacity: 0;
    pointer-events: none;
    ${({ theme }) => theme.easing("0.5")}
    }

    &.active:after{
    opacity: 1;
    }

  }

  .parallax-outer{
  width: 100%;
  }

  .capsule_button{
  text-decoration:none;
  text-transform: uppercase;
  border-radius: 6rem;
  position: relative;
  display: inline-flex;
  align-items:center;
  transition:0.5s background ease-in-out, 0.5s color ease-in-out, 0.5s border ease-in-out;
  ${({ theme }) => theme.tomato("bold")}
  padding:1rem;
  font-size:1.2rem;
  cursor: pointer;
  
    @media only screen and (min-width: 500px) {
    padding:clamp(1rem, 1vw, 1.5rem) clamp(1.5rem, 1.25vw, 3rem);
    font-size:clamp(1.4rem, 0.9vw, 2rem);    
    }

    &.small{
    padding:1rem !important;
    }

    &.visibilityHidden{
    visibility: hidden !important; 
    }

    &.disabled{
    opacity:0.1;
    pointer-events:none !important;
    }
  

    &.red{
      background: #d02600;
      color: var(--white);
      border: 1px solid #d02600 !important;

      &:hover{
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--black) !important;
      }
    }

    &.artist-development-signup-button{
      @media only screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
      display: block;
      padding:2rem
      }
    }

    &.white{
    background:var(--white);
    color:var(--black);
    border:1px solid var(--black);

      .asterisk{
      margin-right: 1rem;
        path{
        ${({ theme }) => theme.easing("0.5")}
        }
      }


      &:hover{
      background:rgba(0,0,0,1);
      color:var(--white); 
      border:1px solid var(--white);

        .asterisk path{
          fill:var(--white);
        }

      }
    }

    &.border_white{
    border:1px solid var(--white) !important;

      &:hover{
        border:1px solid var(--black) !important;
      }

      &.border_hover:hover{
        border:1px solid var(--white) !important;
      }

    }
  
    &.transparent{
    border:1px solid var(--white);
    color:var(--white);

      &:hover{
      background:rgba(0,0,0,0.5);
      color:var(--white); 
      }

      &.dark{
      border:1px solid var(--black);
      color:var(--black); 

          &:hover{
          background:var(--black);
          color:var(--white); 
          border:1px solid var(--white);
          }

      }
    }
    
    &.black{
    border:1px solid var(--black);
    color:var(--white);
    background:var(--black);

      &:hover{
      background:var(--white);
      color:var(--black); 
      }

      &.active{
      color:var(--white) !important;
      background:var(--black) !important;
      }
    }

  }

  .contact_button_fixed{
  position: fixed;
  bottom: clamp(2rem,4vw,8rem);
  right: clamp(2rem,4vw,8rem);
  z-index: 1000;
  display: flex;
  gap:2rem;
  }

  .flashButton--white{
  animation: flash 2s linear 0s infinite forwards;
  }
  .flashButton--black{
  animation: flash-black 2s linear 0s infinite forwards;
  }


  @keyframes flash {
    0% {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--white);
    }
    40% {
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--white);
    }
    60% {
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--white);
    }
    100% {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--white);
    }
  }

  @keyframes flash-black {
    0% {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--black);
    }
    40% {
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--black);
    }
    60% {
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--black);
    }
    100% {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--black);
    }
  }

  #snipcart{
    z-index: 999999;
    position: relative;

    .snipcart-cart-header{
    flex-wrap: wrap;
    }

    .snipcart-cart-header__count{
    display: none;
    }

    .snipcart-cart__content{
      border-top: 1px solid var(--grey_mid);
    }
    .snipcart-cart__content,
    .snipcart-cart-header,
    .snipcart-modal,
    .snipcart-modal__container{
    background:var(--white);
    }

    .snipcart-item-line{
    box-shadow:none;
    }
    .snipcart-item-line__title,
    .snipcart__font--secondary{
    ${({ theme }) => theme.tomato("regular")}
    }

    .snipcart-item-line__title{
    text-transform: uppercase;
    }

    .snipcart-cart-summary--edit.snipcart-modal__container{
    max-width: inherit !important;
    }

    .snipcart-cart__featured-payment-methods-container{
    display: none;
    }

    .snipcart-textbox--focus,
    .snipcart-input:focus-within{
      border: 1px solid var(--black);
      box-shadow: 0 0 0 0;
    }

    .snipcart-typeahead__suggestions,
    .snipcart-checkbox:hover+label:before,
    .snipcart-form__select:focus{
      border: 1px solid var(--black);
    }

    .snipcart__font--subtitle{
      text-transform: uppercase;
      font-size:3rem;

        &:after{
        width:25px;
        height:25px;
        margin-left:1rem;
        margin-top:0.3rem;
        background:url(${Arrow}) no-repeat;
        background-size:contain;
        content:"";
        }

    }
    
    .snipcart-checkbox:hover+label:before,
    .snipcart-textbox,
    .snipcart-form__select,
    .snipcart-input{
      ${({ theme }) => theme.easing("0.5")}
    }
    /* .snipcart-form__select{
    ${({ theme }) => theme.easing("0.5")}
    }
    .snipcart-form__select:focus {
    outline: none;
    border: 1px solid var(--black);
    } */

    .snipcart-cart-button{
    background:var(--black);
    text-transform: uppercase;
    border-radius: 3rem;
    justify-content: center;

      .snipcart-cart-button__icon,
      svg{
      display: none;
      }

    }

    .snipcart__box--badge-highlight{
    background:var(--black);
    }

    .snipcart__actions--link{
    ${({ theme }) => theme.tomato("bold")}
    color:var(--black);
    }
    .snipcart__icon--blue-light path,
    .snipcart__icon--blue-dark path{
      fill:var(--black);
    }

    .snipcart-item-line__container{
    border-bottom:1px solid var(--grey_mid);
    /* flex-wrap: wrap; */
    }

    .snipcart-item-line__header{
      @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;

        h2{
        width: 100%;
        }
      }
    }

    .snipcart-item-line__product{
    width:100%;
    position: relative;

      @media only screen and (min-width: 1024px) {
       // width: calc(100% - 150px - 2rem);
      }
    }

    .snipcart-item-quantity__quantity{
    border-radius: 3rem;
    border: 1px solid var(--grey_light);
    }

    .snipcart-item-line__actions{
      @media only screen and (max-width: 1024px) {
      position: absolute;
      top:0;
      right:0;
      }
    }

    .snipcart-item-quantity__button:focus path{
      fill:var(--grey);
    }

    .custom_snipcart_header{
    width:100%;
    padding-top:10rem;

      h1{
      ${({ theme }) => theme.tomato("regular")}
      font-size:clamp(3rem, 5vw, 10rem);
      letter-spacing: -0.09em;
      text-transform: uppercase;
      }
    }

    .snipcart-item-line__media{
    width: 150px;
    margin-right: 2rem;
    /* height: 150px; */

      @media only screen and (max-width: 1024px) {
      margin-bottom: 2rem;
      min-height: 150px;
      }

      img{
      width: 100%;
      height:100%;
      object-fit: cover;
      }

    }

  }

  .testimonials-hero{
    position: absolute;
    height:100vh;
    width: 50%;
    top:0;
    left:0;
    background:var(--black);
    z-index: 120;

        .image,
        img,
        video{
        width:100%;
        height:100%;
        object-fit:contain;
        }
        img{
        object-fit:cover;
        }

        .play-icon{
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        display: flex;
        align-items:center;
        justify-content: center;
        ${({ theme }) => theme.easing("0.5")}
        z-index: 100;
        cursor: pointer;
        
          &:hover{
            transform:scale(1.2);
          }

          &.active{
            transform:scale(3);
            opacity: 0;
          }

          &:after{
          content:"";
          width:100px;
          height:100px;
          background:url(${Play});
          background-size:contain;
          }

        }

    }

    .fakeForm,
    .fakeForm-b,
    form{
    width: 100%;

      .capsule_button{
      margin-bottom: 4rem;
      }
    
      .buttons{
          .fakeButton{
          border:0;
          padding:0;
          border-bottom:1px solid rgba(0,0,0,0);
          ${({ theme }) => theme.easing("0.5")}
          cursor:pointer;

              &:hover{
              border-bottom: 1px solid rgba(0,0,0,1);
              }
          }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        border: 1px solid var(--grey);
        -webkit-text-fill-color: var(--grey);
        -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
        transition: background-color 5000s ease-in-out 0s;
        background:var(--white);
        -webkit-appearance:none;
      }

      .form-styles{
      width: 100%;
      display: grid;
      grid-gap:2rem;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 4rem;
      
          .col-1{
          grid-column-start: 1;
          grid-column-end: 3;
          }

          .col-2{
              @media only screen and (max-width: 500px) {
              grid-column-start: 1;
              grid-column-end: 3;
              }
          }



          .buttons{
          font-size:clamp(1rem, 0.8vw, 1.8rem);
          line-height:clamp(1rem*1.25, 0.8vw*1.25, 1.8rem*1.25);
          text-align:right;
          }

          .validation-message{
          color:red;
          width:100%;
          grid-column-start: 1;
          grid-column-end: 3;
          font-size:clamp(1rem, 0.8vw, 1.8rem);
          line-height:clamp(1rem*1.25, 0.8vw*1.25, 1.8rem*1.25);
          display:none;
          }
          
          .fakeInput,
          input[type="text"],
          input[type="email"],
          input[type="password"],
          textarea,
          select{
          width: 100%;
          background:none;
          padding:1.5rem;
          border:1px solid var(--grey_mid);
          border-radius: 0.5rem;
          ${({ theme }) => theme.easing("0.5")}
          color:var(--grey);
          -webkit-appearance:none;


              &::placeholder{
              color:var(--grey);
              }

              &:focus{
              outline:none;
              color:var(--black);
              border:1px solid var(--black);
              }
          }

          select{
          background-image:url(${DD});
          background-repeat:no-repeat;
          background-position: calc(100% - 1rem) center;
          }

          &.login-failed{
            input[type="text"],
            input[type="password"]{
              border:1px solid red;
              background:red;
              color:white;

                &::placeholder{
                color:white;
                }
            }

            .validation-message{
            display:block;
            }
          }

          .checkbox-wrapper{
          display: flex;
          align-items:center;
          align-content: center;
          cursor: pointer;
          font-size:clamp(1rem, 0.8vw, 1.8rem);
          line-height:clamp(1rem*1.25, 0.8vw*1.25, 1.8rem*1.25);
          position: relative;

              label{
                  cursor: pointer;
              }

              span{
              position: relative;
              }

              input[type="checkbox"]{
              border:0;
              background:none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 10;

                  + span{
                  width:15px;
                  height:15px;
                  display:inline-block;
                  border:1px solid var(--grey_mid);
                  margin-right:1rem;
                  border-radius: 0.25rem;
                  cursor: pointer;
                  overflow: hidden;
                  position: relative;

                      &:after{
                      content:"";
                      width:100%;
                      height:100%;
                      border-radius: 100%;
                      background:black;
                      position: absolute;
                      top:0;
                      left:0;
                      transform: scale(0);
                      ${({ theme }) => theme.easing("0.5")}
                      }

                  }

                  &:checked + span:after{
                  transform: scale(1.5);
                  }

              }

          }

          
          
          .capsule_button{
          cursor:pointer;
          display:flex;
          justify-content:center;
          align-items:center;
          text-align:center;
          }
  
  
          .fakeCapsuleButton{
          display:none;

            .svgWrap{
            margin-left:1rem;
            animation:rotate 1s linear 1s infinite;
            }

            @keyframes rotate {
              0% {
                transform:rotate(0deg);
              }
            
              100% {
                transform:rotate(360deg);
              }
            }

          }
  
          &.login-inProgress{
            .fakeCapsuleButton{
              display:flex;
            }
            .realCapsuleButton{
            display:none;
            }
          }
        }


    }

    .forms-wrapper{
      width:100%;
      position:relative;
  
          .fakeForm{
          position:absolute;
          width:100%;
          top:0;
          transform:translateX(150%);
          
              .form-styles{
              display:block !important;
              }
  
          }
  
          .fakeForm-b{
          width:100%;
          
              .form-styles{
              display:block !important;

                .form-step-2-b,
                .form-step-3-b,
                .form-step-4-b{
                position:absolute;
                top:0;
                }   

                .form-step-2-b{
                transform:translateX(150%)
                }
                .form-step-3-b{
                transform:translateX(300%)
                }
                .form-step-4-b{
                transform:translateX(450%)
                }

              }
  
          }
  
          .form-step{
          width:100%;
          position:relative;
  
              input{
              margin-bottom:2rem;
              }

              p{
              margin-bottom: 1rem;
              line-height: 1.5em;

                &:last-child,
                &:last-of-type{
                margin-bottom: 0;
                }

              }
  
              &.form-step-2,
              &.form-step-3{
              position:absolute;
              top:0;
              }   
  
              &.form-step-2{
              transform:translateX(150%)
              }
              &.form-step-3{
              transform:translateX(300%)
              }
  
          }
  
      }

    .pin-spacer{
    pointer-events:none;

      @media only screen and (max-width: 850px) {
      display: none !important;
      }

    }
`;
