
import styled from 'styled-components'

const Styling = styled.header`
padding:clamp(2rem, 2vw, 4rem)  clamp(2rem, 4vw, 8rem);
font-size:clamp(1.5rem, 1vw, 1.8rem);
position: fixed !important;
top:0;
left:0;
width: 100%;
z-index: 10000 !important;
mix-blend-mode: difference;

    
    &.course-player-header{
    opacity: 0;
    pointer-events: none;
    }

    .desktop{
    display: none;

        @media only screen and (min-width: 1024px) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        }

    }

    
    .mobile-header{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

        @media only screen and (min-width: 1024px) {
        display: none;
        }
    }

    
    .logo{
    width:clamp(12rem, 9.5vw, 18rem);
    }

    ul{
    display: inline-flex;
    align-items: center;

        li{
        margin-left: 2rem;

            &:first-child{
            margin-left:0;
            }

            .fake_link{
            padding:0;
            border:0;
            }

            .fake_link,
            button span.text,
            a{
            color:var(--white);
            text-decoration:none;
            position: relative;
                
                &:after{
                content:"";
                width:0.7rem;
                height:0.7rem;
                background:var(--white);
                position: absolute;
                bottom:calc(-100% + 0.15rem);
                left:calc(50% - 0.35rem);
                border-radius: 100%;
                transform:scale(0);
                ${({theme}) => theme.easing('0.25')}  
                }

                &[aria-current="page"]:after,
                &:hover:after{
                transform: scale(1);
                }
            }

        }

    }

    button.cart,
    button.login{
    background:none;
    border:0;
    color: var(--white);
    cursor: pointer;

        .item_count{
        padding:1.5rem;
        margin-left:1rem;
        position: relative;
        pointer-events: none;
        display: inline-block;
/*         
            &:before{
            position: absolute;
            top:50%;
            border:2px solid var(--white);
            left:50%;
            transform:translate(-50%, -50%);
            width:100%;
            padding-bottom: 100%;
            content:"";
            border-radius: 100%;
            height: 0;
            } */

        }

    }
    .cart_and_toggle_wrap{
    display: flex;
    }

    .navToggle{
    width:4rem;
    height: 4rem;
    display: inline-block;
    margin-left:2rem;
    position: relative;
    cursor: pointer;
    ${({theme}) => theme.easing('0.5')}  
    
        .line{
        width: 3rem;
        height: 2px;
        background:var(--white);
        position: absolute;
        ${({theme}) => theme.easing('0.5')}  

            &.top{
            top:calc(50% - 7px);
            transform-origin: top left;
            }
            &.middle{
            top:50%;
            }
            &.bottom{
            top:calc(50% + 7px);
            transform-origin: bottom left;
            }

        }
    }

`

export { Styling }