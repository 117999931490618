import styled from "styled-components";

const Styling = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.95);
  min-width: 400px;
  height: auto;
  padding: 1rem;
  z-index: 10000;
  color: var(--white);
  display: flex;
  align-items: center;
  font-family: var(--font_body);
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s transform ease-in-out, 0.5s opacity ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-right: 1rem;
  }

  .capsule_button {
    margin-left: 1rem;
  }
`;

export { Styling };
