import React, { useEffect, useState } from "react";
import { Styling } from "./styled.js";

export default function SnipcartItemAdded({ isVisible, item }) {
  const { Snipcart } = typeof window !== "undefined" ? window : "";
  const [isSnipcartItemAddedVisible, setIsSnipcartItemAddedVisible] =
    useState(false);
  const [snipcartItem, setSnipcartItem] = useState();
  useEffect(() => {
    if (Snipcart !== undefined || Snipcart !== "undefined") {
      Snipcart.events.on("item.adding", (cartConfirmResponse) => {
        console.log(cartConfirmResponse);
        setSnipcartItem(cartConfirmResponse);
        setIsSnipcartItemAddedVisible(true);
        setTimeout(() => {
          setIsSnipcartItemAddedVisible(false);
        }, 6000);
      });
    }
  }, [Snipcart]);

  return (
    <Styling className={isSnipcartItemAddedVisible ? "visible" : ""}>
      <img className="image" src={snipcartItem?.image} />
      <div className="name">
        {snipcartItem?.name} has been added to your cart.
        <div className="snipcart-checkout capsule_button white">View cart</div>
      </div>
    </Styling>
  );
}
